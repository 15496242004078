'use strict';
import "./styles.scss";
// Dependencies
import * as React from "react";
export default function ServerItem(props) {
    const { isActive, name, image, href, } = props;
    const className = 'ServerItem' + ' ' + (isActive ? '' : 'inactive');
    return React.createElement("a", { href: (isActive && href) ? href : '#', className: className },
        React.createElement("img", { className: 'ServerItem__img', src: image }),
        React.createElement("p", { className: 'ServerItem__title' }, name));
}
