'use strict';
// Dependencies
import * as React from "react";
import "./assets/styles/global.scss";
// Components
import ServerItem from "./components/ServerItem/ServerItem";
const SERVERS = [
    {
        image: './img/vpn.webp',
        name: 'VPN',
        isActive: true,
        href: './vpn/',
    },
    {
        image: './img/wtd.jpg',
        name: 'WhaToDo',
        isActive: true,
        href: './whatodo/adder',
    },
    {
        image: './img/mc.webp',
        name: 'Minecraft',
        isActive: false,
        href: './minecraft/',
    },
    {
        image: './img/wc3.webp',
        name: 'WarCraft III',
        isActive: true,
        href: './warcraft/',
    },
    {
        image: './img/cod4.webp',
        name: 'Call Of Duty 4',
        isActive: true,
        href: './cod4/',
    },
    {
        image: './img/zomboid.jpg',
        name: 'Project Zomboid',
        isActive: false,
        href: '',
    },
];
export default function App() {
    return React.createElement("div", { className: 'main' },
        React.createElement("div", { className: 'main__servers' }, SERVERS.map((itemInfo) => React.createElement(ServerItem, Object.assign({}, itemInfo, { key: itemInfo.name })))));
}
